/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "../variables";
@import "../mixins";

.mdl-dialog {
    border: none;
    @include shadow-24dp;
    @include dialog-width;

    &__title {
        padding: 24px 24px 0;
        margin: 0;
        font-size: 2.5rem;
    }
    &__actions {
        padding: 8px 8px 8px 24px;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        > * {
            margin-right: 8px;
            height: 36px;
            &:first-child {
                margin-right: 0;
            }
        }
        &--full-width {
          padding: 0 0 8px 0;
          > * {
            height: 48px;
            flex: 0 0 100%;
            padding-right: 16px;
            margin-right: 0;
            text-align: right;
          }
        }
    }
    &__content {
        padding: 20px 24px 24px 24px;
        color: $dialog-content-color;
    }
}
