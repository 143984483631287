/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "../variables";

.mdl-ripple {
  background       : $ripple-bg-color;
  border-radius    : 50%;
  height           : 50px;
  left             : 0;
  opacity          : 0;
  pointer-events   : none;
  position         : absolute;
  top              : 0;
  transform        : translate(-50%, -50%);
  width            : 50px;
  overflow         : hidden;

  &.is-animating {
    transition: transform 0.3s $animation-curve-linear-out-slow-in,
    width 0.3s $animation-curve-linear-out-slow-in,
    height 0.3s $animation-curve-linear-out-slow-in,
    opacity 0.6s $animation-curve-linear-out-slow-in;
  }

  &.is-visible {
    opacity: 0.3;
  }
}
