/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "../variables";
@import "../mixins";

.mdl-shadow--2dp {
  @include shadow-2dp();
}

.mdl-shadow--3dp {
  @include shadow-3dp();
}

.mdl-shadow--4dp {
  @include shadow-4dp();
}

.mdl-shadow--6dp {
  @include shadow-6dp();
}

.mdl-shadow--8dp {
  @include shadow-8dp();
}

.mdl-shadow--16dp {
  @include shadow-16dp();
}

.mdl-shadow--24dp {
  @include shadow-24dp();
}
