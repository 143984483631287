/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "h5bp";
@import "mobile";

/*
 * Make html take up the entire screen
 * Then set touch-action to avoid touch delay on mobile IE
 */
html {
  width: 100%;
  height: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/*
* Make body take up the entire screen
* Remove body margin so layout containers don't cause extra overflow.
*/
body {
  width: 100%;
  min-height: 100%;
  margin: 0;
}

/*
 * Main display reset for IE support.
 * Source: https://weblog.west-wind.com/posts/2015/Jan/12/main-HTML5-Tag-not-working-in-Internet-Explorer-91011
 */
main {
  display: block;
}

/*
* Apply no display to elements with the hidden attribute.
* IE 9 and 10 support.
*/
*[hidden] {
  display: none !important;
}
